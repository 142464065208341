import LossProfit from 'assets/icons/LossProfit.svg'
import IncomeIcon from 'assets/icons/pemasukan-2.svg'
import ExpenseIcon from 'assets/icons/pengeluaran-2.svg'
import ProfitIcon from 'assets/icons/ProfitIcon.svg'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'

const ProfitLossCardSection = ({
  res,
  totalExpense,
  totalIncome,
  totalProfit,
}) => {
  return (
    <Row className="gy-4 gx-md-4 align-items-center">
      <Col xs="12" md="4">
        <Card
          className="p-4 border-0 shadow-none"
          style={{ backgroundColor: '#F6FEF9', height: '102px' }}
        >
          <CardBody className="d-flex align-items-center gap-3 p-0">
            <img src={IncomeIcon} alt="Pemasukan" width={60} height={60} />
            <div>
              <h6 className="text-dark fw-normal mb-1">Pemasukan</h6>
              {res?.isFetching ? (
                <Spinner size="sm" color="primary" />
              ) : (
                <h5 className="text-success fw-bold m-0">
                  Rp{newFormatThousandSeparator(totalIncome)}
                </h5>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xs="12" md="4">
        <Card
          className="p-4 border-0 shadow-none"
          style={{ backgroundColor: '#FFFBFA', height: '102px' }}
        >
          <CardBody className="d-flex align-items-center gap-3 p-0">
            <img src={ExpenseIcon} alt="Pengeluaran" width={60} height={60} />
            <div>
              <h6 className="text-dark fw-normal mb-1">Pengeluaran</h6>
              {res?.isFetching ? (
                <Spinner size="sm" color="primary" />
              ) : (
                <h5 className="text-danger fw-bold m-0">
                  Rp{newFormatThousandSeparator(totalExpense)}
                </h5>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xs="12" md="4">
        <Card
          className="p-4 border-0 shadow-none"
          style={{
            backgroundColor: totalProfit >= 0 ? '#F3FEFF' : '#FFFCF5',
            height: '102px',
          }}
        >
          <CardBody className="d-flex align-items-center gap-3 p-0">
            <img
              src={totalProfit >= 0 ? ProfitIcon : LossProfit}
              alt={totalProfit >= 0 ? 'Keuntungan' : 'Kerugian'}
              width={60}
              height={60}
            />
            <div>
              <h6 className="text-dark fw-normal mb-1">
                {totalProfit >= 0 ? 'Keuntungan' : 'Kerugian'}
              </h6>
              {res?.isFetching ? (
                <Spinner size="sm" color="primary" />
              ) : (
                <h5
                  className={`fw-bold m-0 ${totalProfit >= 0 ? 'text-primary' : 'text-danger'}`}
                >
                  {totalProfit >= 0 ? '+ ' : '- '}
                  Rp{newFormatThousandSeparator(Math.abs(totalProfit))}
                </h5>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ProfitLossCardSection
