import arusKasIcon from 'assets/icons/arus-kas.svg'
import dataIcon from 'assets/icons/data.svg'
import docs from 'assets/icons/docs.svg'
import fishIcon from 'assets/icons/fish.svg'
import IconCompanies from 'assets/icons/icon-companies.svg'
import IconFish from 'assets/icons/icon-fish.svg'
import IconFisheries from 'assets/icons/icon-fisheries.svg'
import IconGears from 'assets/icons/icon-gears.svg'
import IconPorts from 'assets/icons/icon-ports.svg'
import IconReport from 'assets/icons/icon-report.svg'
import IconShip from 'assets/icons/icon-ship.svg'
import insightIcon from 'assets/icons/insight.svg'
import logIcon from 'assets/icons/log.svg'
import piutangIcon from 'assets/icons/piutang.svg'
import reverseArrow from 'assets/icons/reverse-arrow.svg'
import switchVerticalIcon from 'assets/icons/switch-vertical.svg'
import utangIcon from 'assets/icons/utang.svg'
import {
  Book,
  BookOpen,
  Box,
  CheckCircle,
  Home,
  Map,
  Repeat,
  Users,
} from 'react-feather'
import {
  ARUS_KAS,
  HISTORY_ARUS_KAS,
  URL_AP2HI_INSIGHT,
  URL_AP2HI_LOG,
  URL_APPROVAL_BUY,
  URL_CATCHES,
  URL_COMPANIES,
  URL_FARMERS,
  URL_FISHERIES,
  URL_GEARS,
  URL_INVENTORY,
  URL_INVENTORY_MEMBERS,
  URL_KURSUS,
  URL_LEARNIING_REPORT,
  URL_PIUTANG,
  URL_PORTS,
  URL_SPATIAL,
  URL_TRANSFER_INVENTORY,
  URL_UTANG,
  URL_VESSELS,
  URL_WAREHOUSE,
  URL_WHISTLE_BLOWINGS,
} from 'utils/pathUrl'
import {
  BUSINESS_AR_AP,
  BUSINESS_AUTOREPO,
  BUSINESS_BOOKKEEPING,
  BUSINESS_FARMERS,
  BUSINESS_INVENTORY,
  BUSINESS_INVENTORY_MEMBER,
  BUSINESS_LEARNING,
  BUSINESS_LEARNING_REPORT,
  BUSINESS_MEMBER_DISTRIBUTION,
  BUSINESS_MYAPPROVAL,
  BUSINESS_TALANGIN,
  BUSINESS_TOPUP,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_TRANSFER_INVENTORY,
  BUSINESS_VESSELS,
  BUSINESS_WAREHOUSE,
  BUSINESS_WITHDRAWAL,
  READ,
} from 'utils/subjectActions'

export const menus = [
  {
    headTitle: 'Transaksi',
    action: READ,
    icon: (
      <img src={reverseArrow} id="tansaksi" alt="tansaksi" loading="lazy" />
    ),
    subject: [
      BUSINESS_TALANGIN,
      BUSINESS_TOPUP,
      BUSINESS_TRANSACTION_PURCHASE,
      BUSINESS_TRANSACTION_SELL,
      BUSINESS_WITHDRAWAL,
      BUSINESS_MYAPPROVAL,
      BUSINESS_AUTOREPO,
    ],
    items: [
      {
        title: 'Daftar Transaksi',
        icon: <Home size={22} id="daftar-transaksi" />,
        path: '/',
        action: READ,
        subject: [
          BUSINESS_TALANGIN,
          BUSINESS_TOPUP,
          BUSINESS_TRANSACTION_PURCHASE,
          BUSINESS_TRANSACTION_SELL,
          BUSINESS_WITHDRAWAL,
          BUSINESS_FARMERS,
        ],
      },
      {
        title: 'Repo Komoditas',
        icon: (
          <img
            src={switchVerticalIcon}
            id="repo-komoditas"
            alt="Repo"
            loading="lazy"
          />
        ),
        path: '/repo-komoditas',
        action: READ,
        subject: [BUSINESS_AUTOREPO],
      },
      {
        title: 'Persetujuan',
        icon: <CheckCircle size={22} id="persetujuan" />,
        path: URL_APPROVAL_BUY,
        action: READ,
        subject: [BUSINESS_MYAPPROVAL],
      },
    ],
  },
  {
    headTitle: 'Manajemen Inventori',
    action: READ,
    icon: <img src={docs} id="catatan" alt="catatan" loading="lazy" />,
    subject: [
      BUSINESS_INVENTORY,
      BUSINESS_WAREHOUSE,
      BUSINESS_TRANSFER_INVENTORY,
    ],
    items: [
      {
        title: 'Gudang',
        icon: <Home size={22} id="gudang" />,
        path: URL_WAREHOUSE,
        action: READ,
        subject: [BUSINESS_WAREHOUSE],
      },
      {
        title: 'Inventori',
        icon: <Box size={22} id="inventori" />,
        path: URL_INVENTORY,
        action: READ,
        subject: [BUSINESS_INVENTORY],
      },
      {
        title: 'Inventori Anggota',
        icon: <Box size={22} id="inventori-anggota" />,
        path: URL_INVENTORY_MEMBERS,
        action: READ,
        subject: [BUSINESS_INVENTORY_MEMBER],
      },
      {
        title: 'Transfer Inventori',
        icon: <Repeat size={22} id="transfer-inventori" />,
        path: URL_TRANSFER_INVENTORY,
        action: READ,
        subject: [BUSINESS_TRANSFER_INVENTORY],
      },
    ],
  },
  {
    headTitle: 'Catatan',
    action: READ,
    icon: <img src={docs} id="catatan" alt="catatan" loading="lazy" />,
    subject: [BUSINESS_AR_AP, BUSINESS_BOOKKEEPING, BUSINESS_LEARNING],
    items: [
      {
        title: 'Laporan Arus Kas',
        icon: (
          <img
            src={arusKasIcon}
            id="arus-kas"
            alt="Laporan Arus Kas"
            loading="lazy"
          />
        ),
        path: ARUS_KAS,
        action: READ,
        subject: [BUSINESS_BOOKKEEPING],
      },
      {
        title: 'Riwayat Arus Kas',
        icon: (
          <img
            src={arusKasIcon}
            id="history-arus-kas"
            alt="Riwayat Arus Kas"
            loading="lazy"
          />
        ),
        path: HISTORY_ARUS_KAS,
        action: READ,
        subject: [BUSINESS_BOOKKEEPING],
      },
      {
        title: 'Utang',
        icon: <img src={utangIcon} id="utang" alt="Utang" loading="lazy" />,
        path: URL_UTANG,
        action: READ,
        subject: [BUSINESS_AR_AP],
      },
      {
        title: 'Piutang',
        icon: (
          <img src={piutangIcon} id="piutang" alt="Piutang" loading="lazy" />
        ),
        path: URL_PIUTANG,
        action: READ,
        subject: [BUSINESS_AR_AP],
      },
      {
        title: 'Kursus',
        icon: <Book size={22} id="kursus" />,
        path: URL_KURSUS,
        action: READ,
        subject: [BUSINESS_LEARNING],
      },
    ],
  },
  {
    headTitle: 'Manajemen Anggota',
    icon: <Users size={22} id="daftar-anggota" color="#667085" />,
    action: READ,
    subject: [BUSINESS_FARMERS, BUSINESS_MEMBER_DISTRIBUTION],
    items: [
      {
        title: 'Daftar Anggota',
        icon: <Users size={22} id="daftar-anggota" />,
        path: URL_FARMERS,
        action: READ,
        subject: [BUSINESS_FARMERS],
      },
      {
        title: 'Laporan Pembelajaran',
        icon: <BookOpen size={22} id="laporan-pembelajaran" />,
        path: URL_LEARNIING_REPORT,
        action: READ,
        subject: [BUSINESS_LEARNING_REPORT],
      },
      {
        title: 'Persebaran Anggota',
        icon: <Map size={22} id="persebaran-anggota" />,
        path: URL_SPATIAL,
        action: READ,
        subject: [BUSINESS_MEMBER_DISTRIBUTION],
      },
      // {
      //   title: 'Peran dan Hak Akses',
      //   icon: <Settings size={22} id="peran-dan-hak-akses" />,
      //   path: URL_ACCESS_ROLE,
      //   action: READ,
      //   subject: BUSINESS_FARMERS,
      // },
    ],
  },
  {
    headTitle: 'Operasi AP2HI',
    action: READ,
    icon: <img src={fishIcon} id="piutang" alt="Piutang" loading="lazy" />,
    subject: [BUSINESS_VESSELS],
    items: [
      {
        title: 'Perjalanan',
        icon: (
          <img src={IconFish} id="perjalanan" alt="perjalanan" loading="lazy" />
        ),
        path: URL_CATCHES,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
      {
        title: 'Pengaduan',
        icon: (
          <img src={IconReport} id="pengaduan" alt="pengaduan" loading="lazy" />
        ),
        path: URL_WHISTLE_BLOWINGS,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
      {
        title: 'Log History',
        icon: <img src={logIcon} id="log-history" alt="Log History" />,
        path: URL_AP2HI_LOG,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
      {
        title: 'Laporan Tangkapan',
        icon: (
          <img
            src={insightIcon}
            id="laporan-tangkapan"
            alt="Laporan Tangkapan"
          />
        ),
        path: URL_AP2HI_INSIGHT,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
    ],
  },
  {
    headTitle: 'Master Data',
    action: READ,
    icon: <img src={dataIcon} id="piutang" alt="Piutang" loading="lazy" />,
    subject: [BUSINESS_VESSELS],
    items: [
      {
        title: 'Vessel',
        icon: <img src={IconShip} id="vessel" alt="vessel" loading="lazy" />,
        path: URL_VESSELS,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
      {
        title: 'Port',
        icon: <img src={IconPorts} id="port" alt="port" loading="lazy" />,
        path: URL_PORTS,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
      {
        title: 'Gear',
        icon: <img src={IconGears} id="gear" alt="gear" loading="lazy" />,
        path: URL_GEARS,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
      {
        title: 'Fishery',
        icon: (
          <img src={IconFisheries} id="fishery" alt="fishery" loading="lazy" />
        ),
        path: URL_FISHERIES,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
      {
        title: 'Company',
        icon: (
          <img src={IconCompanies} id="company" alt="company" loading="lazy" />
        ),
        path: URL_COMPANIES,
        action: READ,
        subject: [BUSINESS_VESSELS],
      },
    ],
  },
]
