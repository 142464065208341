import { TableComponent as DataTable } from 'components/TableComponent'
import moment from 'moment-timezone'
import Chart from 'react-apexcharts'
import { Spinner } from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'

const MonthlyTab = ({ selectedMonthYear, dataCashFlow, res }) => {
  const generateMonthlyData = (selectedMonthYear, rawData) => {
    const selectedMonth = moment(selectedMonthYear).format('YYYY-MM')
    const daysInMonth = moment(selectedMonthYear).daysInMonth()

    const monthlyData = Array?.from({ length: daysInMonth }, (_, index) => {
      const fullDate = moment(
        `${selectedMonth}-${index + 1}`,
        'YYYY-MM-DD',
      ).format('D MMMM YYYY')
      return {
        date: fullDate,
        income: 0,
        expense: 0,
        profit: 0,
      }
    })

    rawData?.forEach((item) => {
      const dayIndex = moment(item?.createddate).date() - 1
      if (monthlyData[dayIndex]) {
        if (item?.incomeorexpense === 'income') {
          monthlyData[dayIndex].income += item?.amount
        } else if (item?.incomeorexpense === 'expense') {
          monthlyData[dayIndex].expense += item?.amount
        }

        monthlyData[dayIndex].profit =
          monthlyData[dayIndex].income - monthlyData[dayIndex].expense
      }
    })

    return monthlyData
  }

  const processedMonthlyData = generateMonthlyData(
    selectedMonthYear,
    dataCashFlow?.items || [],
  )

  const columnsMonthly = [
    {
      name: <span>Periode</span>,
      selector: (row) => row?.date,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pemasukan</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.income)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pengeluaran</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.expense)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Untung/Rugi</span>,
      selector: (row) => (
        <span style={{ color: row.profit >= 0 ? '#027A48' : '#B42318' }}>
          {row.profit >= 0 ? '+ ' : '- '}
          Rp{newFormatThousandSeparator(Math.abs(row.profit))}
        </span>
      ),
      wrap: true,
      grow: 2,
    },
  ]

  const optionsMonthly = {
    chart: {
      type: 'bar',
      height: 500,
      zoom: { enabled: false },
      toolbar: { show: false },
      stacked: false,
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: '25px',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        shape: 'circle',
      },
    },
    colors: ['#039855', '#D92D20'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return newFormatThousandSeparator(value)
        },
      },
    },
    xaxis: {
      categories: processedMonthlyData.map((data) => data.date),
      labels: { style: { fontSize: '12px', colors: '#6E6E6E' } },
    },
    title: {
      text: 'Arus Kas Bulanan (Rupiah)',
      offsetY: 5,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return newFormatThousandSeparator(value)
        },
        style: { fontSize: '12px', colors: '#6E6E6E' },
      },
    },
  }

  const seriesMonthly = [
    {
      name: 'Pemasukan',
      data: processedMonthlyData.map((data) => data.income),
    },
    {
      name: 'Pengeluaran',
      data: processedMonthlyData.map((data) => data.expense),
    },
  ]

  return (
    <>
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
          padding: '20px 0px',
        }}
      >
        <Chart
          options={optionsMonthly}
          series={seriesMonthly}
          type="bar"
          height={400}
        />
      </div>

      <DataTable
        columns={columnsMonthly}
        data={processedMonthlyData}
        progressPending={res?.isFetching}
        progressComponent={
          <div style={{ margin: '100px 0px' }}>
            <Spinner color="primary" />
          </div>
        }
      />
    </>
  )
}

export default MonthlyTab
