import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import PMBSelect from 'components/PMBSelect'
import PMBTabSwitcher from 'components/PMBTabSwitcher'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { Calendar } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import { useLazyGetArusKasQuery } from './cashFlowApiSlice'
import { setFetchOption, setFilters } from './cashFlowSlice'
import AnnualTab from './tabs/AnnualTab'
import MonthlyTab from './tabs/MonthlyTab'
import ProfitLossCardSection from './tabs/ProfitLossCardSection'

const CashFlow = () => {
  const dispatch = useDispatch()
  const { filters, tag } = useSelector((s) => s.cashflow)
  const { profile } = useSelector((s) => s.dashboard)

  const selectedYearAnnual = filters.selectedYearAnnual
  const selectedMonthYear = filters.selectedMonthYear

  const [getArusKas, res] = useLazyGetArusKasQuery()

  const [dataIncome, setDataIncome] = useState([])
  const [dataExpense, setDataExpense] = useState([])
  const [totalIncome, setTotalIncome] = useState(0)
  const [totalExpense, setTotalExpense] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)
  const [yearOptionsAnnual, setYearOptionsAnnual] = useState([])
  const [activeTab, setActiveTab] = useState('tahunan')
  const [dataCashFlow, setDataCashFlow] = useState(null)

  const selectedMonth = moment(selectedMonthYear)?.format('YYYY-MM')
  const daysInMonth = moment(selectedMonth, 'YYYY-MM').daysInMonth()

  const startdate =
    activeTab === 'tahunan'
      ? `${selectedYearAnnual?.value || new Date().getFullYear()}-01-01`
      : `${selectedMonth}-01`

  const enddate =
    activeTab === 'tahunan'
      ? `${selectedYearAnnual?.value || new Date().getFullYear()}-12-31`
      : `${selectedMonth}-${daysInMonth}`

  const updatedEndDate = moment(enddate).add(1, 'day').format('YYYY-MM-DD')

  useEffect(() => {
    const fetchCashFlowData = async () => {
      try {
        const resultResponse = await getArusKas({
          startdate,
          enddate: updatedEndDate,
          filter: undefined,
          page: 0,
          size: 10000,
        }).unwrap()

        const rawData = resultResponse?.items || []
        setDataCashFlow(resultResponse)

        const { totalIncome, totalExpense, incomeByMonth, expenseByMonth } =
          rawData.reduce(
            (acc, item) => {
              const monthIndex = moment(item?.createddate)?.month()

              if (item?.incomeorexpense === 'income') {
                acc.totalIncome += item?.amount
                acc.incomeByMonth[monthIndex] += item?.amount
              } else if (item?.incomeorexpense === 'expense') {
                acc.totalExpense += item?.amount
                acc.expenseByMonth[monthIndex] += item?.amount
              }

              return acc
            },
            {
              totalIncome: 0,
              totalExpense: 0,
              incomeByMonth: Array(12).fill(0),
              expenseByMonth: Array(12).fill(0),
            },
          )

        setTotalIncome(totalIncome)
        setTotalExpense(totalExpense)
        setTotalProfit(totalIncome - totalExpense)
        setDataIncome(incomeByMonth)
        setDataExpense(expenseByMonth)
      } catch (error) {
        console.warn('Error fetching cash flow data', error)
      }
    }

    fetchCashFlowData()
  }, [selectedMonthYear, selectedYearAnnual, tag?.page, tag?.size, activeTab])

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const createdAtUser = profile?.createdAt
      ? new Date(profile.createdAt).getFullYear()
      : currentYear

    const years = []
    for (let i = createdAtUser; i <= currentYear; i++) {
      years.push({ value: i, label: String(i) })
    }

    setYearOptionsAnnual(years)

    if (!selectedYearAnnual) {
      dispatch(setFilters({ selectedYearAnnual: years[0] }))
    }
  }, [dispatch, selectedYearAnnual, profile])

  useEffect(() => {
    if (!selectedYearAnnual) {
      const currentYear = new Date().getFullYear()
      const currentYearOption =
        yearOptionsAnnual?.find((option) => option?.value === currentYear) ||
        yearOptionsAnnual[0]

      dispatch(setFilters({ selectedYearAnnual: currentYearOption }))
    }
  }, [dispatch, selectedYearAnnual, yearOptionsAnnual])

  const handleYearChangeAnnual = (selectedOption) => {
    dispatch(setFilters({ selectedYearAnnual: selectedOption }))
  }

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey)
    dispatch(setFetchOption({ page: 0, size: 10 }))
  }

  const handleChangeMonthYear = (selectedDates) => {
    if (selectedDates.length > 0) {
      const date = selectedDates[0]
      dispatch(setFilters({ selectedMonthYear: date }))
    }
  }

  useEffect(() => {
    if (!selectedMonthYear) {
      dispatch(setFilters({ selectedMonthYear: new Date().toISOString() }))
    }
  }, [dispatch, selectedMonthYear])

  if (res?.isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs breadCrumbActive={'Laporan Arus Kas'} />
      </div>

      <Card className="mb-4 p-3" style={{ border: 'none' }}>
        <div className="mb-2 d-flex flex-column gap-3">
          <PMBTabSwitcher
            activeTab={activeTab}
            tabs={[
              { key: 'tahunan', label: 'Tahunan' },
              { key: 'bulanan', label: 'Bulanan' },
            ]}
            onTabChange={handleTabChange}
          />
        </div>

        <Row
          className="gy-3 align-items-center justify-content-between"
          style={{ marginBottom: 24 }}
        >
          <Col xs="12" sm="6" className="text-center text-sm-start">
            <h5 className="mb-0">Laporan Arus Kas</h5>
          </Col>
          <Col
            xs="12"
            sm="6"
            className="d-flex justify-content-center justify-content-sm-end"
          >
            <div style={{ width: '100%', maxWidth: '300px' }}>
              {activeTab === 'tahunan' ? (
                <PMBSelect
                  value={selectedYearAnnual}
                  onChange={handleYearChangeAnnual}
                  options={yearOptionsAnnual}
                  isMulti={false}
                  placeholder="Pilih tahun"
                />
              ) : (
                <div className="d-flex align-items-center justify-content-center border rounded">
                  <Calendar size={17} className="ms-3" />
                  <Flatpickr
                    value={
                      selectedMonthYear
                        ? moment(selectedMonthYear).format('YYYY-MM')
                        : moment().format('YYYY-MM')
                    }
                    onChange={handleChangeMonthYear}
                    options={{
                      altInput: true,
                      altFormat: 'F Y',
                      dateFormat: 'Y-m',
                      defaultDate: selectedMonthYear
                        ? moment(selectedMonthYear).format('YYYY-MM')
                        : moment().format('YYYY-MM'),
                      disableMobile: true,
                      maxDate: moment().format('YYYY-MM'),
                      minDate: profile?.createdAt
                        ? moment(profile.createdAt).format('YYYY-MM-DD')
                        : undefined,
                      plugins: [
                        typeof window !== 'undefined' &&
                        window.matchMedia('(min-width: 480px)').matches
                          ? new MonthSelectPlugin({
                              shorthand: false,
                              dateFormat: 'Y-m',
                              altFormat: 'F Y',
                            })
                          : undefined,
                      ].filter(Boolean),
                    }}
                    className="form-control bg-transparent border-0 shadow-none"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>

        <ProfitLossCardSection
          res={res}
          totalExpense={totalExpense}
          totalIncome={totalIncome}
          totalProfit={totalProfit}
        />

        {activeTab === 'tahunan' && (
          <AnnualTab
            dataCashFlow={dataCashFlow}
            dataIncome={dataIncome}
            dataExpense={dataExpense}
            res={res}
          />
        )}

        {activeTab === 'bulanan' && (
          <MonthlyTab
            selectedMonthYear={selectedMonthYear}
            dataCashFlow={dataCashFlow}
            res={res}
          />
        )}
      </Card>
    </div>
  )
}

export default CashFlow
