import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'

const getLogs = createAsyncThunk(
  'ap2hi/logs',
  async (
    { page = 0, size = 10, search, sortBy, asc, catchId },
    { rejectWithValue, getState },
  ) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/ap2hi/logs?page=${page}&size=${size}`
      if (search) {
        url += `&search=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }
      if (catchId) {
        url += `&catchid=${catchId}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getInsight = createAsyncThunk(
  'ap2hi/insight',
  async ({ from, to, wpp }, { rejectWithValue, getState }) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/ap2hi/chart?from=${from}&to=${to}`

      if (wpp) {
        url += `&filterwpp=${wpp}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getChart2 = createAsyncThunk(
  'ap2hi/chart2',
  async (
    { year, page = 0, size = 10, gear = 'all' },
    { rejectWithValue, getState },
  ) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/ap2hi/chart2?page=${page}&size=${size}&year=${year}&gear=${gear}`

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getChart3 = createAsyncThunk(
  'ap2hi/chart3',
  async ({ month, year }, { rejectWithValue, getState }) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/ap2hi/chart3?year=${year}`

      if (month) {
        url += `&month=${month}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { getChart2, getChart3, getInsight, getLogs }
