import * as FileSaver from 'file-saver'
import moment from 'moment'
import * as XLSX from 'xlsx'

export const formatNotes = (text) => {
  if (!text) return '-'

  let formattedText = text.replace(/\n/g, '<br />')

  if (/\)$/.test(formattedText)) {
    formattedText = formattedText.replace(/\([^)]*\)/g, '')
  }

  return formattedText.trim()
}

export const handleDownloadExcel = (
  res,
  setIsDownload,
  setFirstRows,
  setTotalRows,
) => {
  setIsDownload(false)
  setFirstRows(0)
  setTotalRows(0)

  setTimeout(() => {
    setIsDownload(true)
    setFirstRows(0)
    setTotalRows(res.data.totalItems)

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const bookType = 'xlsx'
    let count = 2

    const totalItems = res.data.totalItems || res.data.items.length
    const chunkSize = Math.max(Math.floor(totalItems * 0.1), 100)

    const exportHeader = [
      'Tanggal Transaksi',
      'Kategori Transaksi',
      'Total Transaksi',
      'Jenis Pencatatan',
      'Catatan',
    ]
    const ws = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(ws, [exportHeader])

    const processChunk = (start) => {
      if (start >= totalItems) {
        setFirstRows(totalItems)
        setTimeout(() => {
          const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
          const excelBuffer = XLSX.write(wb, { bookType, type: 'array' })
          const finalData = new Blob([excelBuffer], { type: fileType })

          FileSaver.saveAs(
            finalData,
            `Riwayat_Arus_Kas_${moment().format('YYYY-MM-DD')}.xlsx`,
          )

          setTimeout(() => {
            setIsDownload(false)
          }, 2000)
        }, 500)

        return
      }

      const chunk = res?.data?.items
        ?.slice(start, start + chunkSize)
        ?.map((row) => [
          row?.createddate && row?.createdhour
            ? moment(
                `${row?.createddate} ${row?.createdhour}`,
                'YYYY-MM-DD HH:mm:ss',
              ).format('Do MMM YYYY, HH:mm')
            : '-',
          row?.extradata?.label ?? row?.label,
          row?.amount,
          row?.manual ? 'Catat Manual' : 'Transaksi',
          row?.notes
            ? formatNotes(row?.notes)?.replace(/<br\s*\/?>/g, '\n')
            : '-',
        ])

      XLSX.utils.sheet_add_json(ws, chunk, {
        origin: `A${count}`,
        skipHeader: true,
      })
      count += chunk.length

      setFirstRows(start + chunk.length)

      setTimeout(() => processChunk(start + chunkSize), 300)
    }

    processChunk(0)
  }, 300)
}
