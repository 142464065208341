import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import {
  useChangeGrupNameMutation,
  useFetchFarmersQuery,
} from './farmersListApiSlice'
import { getGroupList } from '../groups-list/groupListAction'
import { toggleModal } from './farmersListSlice'

const ModalChangeName = ({ groupCode }) => {
  const dispatch = useDispatch()
  const { isOpenModal, tag } = useSelector((s) => s.farmersList)
  let [page, setPage] = useState({ page: tag.page })
  const code = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )
  const [name, setName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [changeName, result] = useChangeGrupNameMutation()
  // const { refetch } = useFetchFarmersQuery(
  //   {
  //     groupCode: code,
  //     page: page?.page,
  //     status: 'JOIN',
  //   },
  //   { skip: !code },
  // )

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await changeName({
        name: name,
        code: groupCode,
      }).unwrap()
      if (data?.data?.meta?.error) {
        throw data
      } else {
        // refetch()
        dispatch(getGroupList())
        dispatch(toggleModal())
      }
    } catch (error) {
      setErrMsg(error?.data?.meta?.message)
    }
  }

  useEffect(() => {
    if (!isOpenModal) {
      setName('')
    }
  }, [isOpenModal])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => {
        dispatch(toggleModal())
        setErrMsg('')
      }}
      autoFocus={false}
    >
      <ModalBody>
        <h5>Masukkan nama grup</h5>
        <p className="text-muted">
          Anda bisa mengganti judul halaman ini dengan nama grup Anda.
        </p>
        <FormGroup>
          <Label>Nama grup</Label>
          <Input
            autoFocus={true}
            value={name ?? ''}
            onChange={(e) => {
              setName(e.target.value)
              setErrMsg('')
            }}
            placeholder="Contoh: Anggota Jakarta Selatan"
          />
        </FormGroup>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3">
          <CustomButton
            onClick={() => {
              dispatch(toggleModal())
              setErrMsg('')
            }}
            disabled={result?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={result?.isLoading}
            onClick={handleSubmit}
            color="primary"
            block
          >
            Selesai
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalChangeName
