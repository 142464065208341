import LoadingV2 from 'components/LoadingV2'
import PMBSelect from 'components/PMBSelect'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useDispatch } from 'react-redux'
import { Col, Row, Spinner } from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { getChart3 } from '../API/ap2hiActions'

const AnnualTab = ({
  yearOptionsAnnual,
  selectedYearAnnual,
  loading: loadingParent,
  handleYearChangeAnnual,
  columnsAnnual,
  dataResultAnnual,
  options,
  series,
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState({
    series: [],
    labels: [],
    total: 0,
    othersTotal: 0,
    othersDetails: [],
    monthlySpecies: [],
  })
  const UNIT_KG = 'kg'
  const MAX_SEGMENTS = 5

  useEffect(() => {
    if (selectedYearAnnual?.value) {
      setLoading(true)

      dispatch(getChart3({ year: selectedYearAnnual?.value }))
        .then((response) => {
          if (response.payload && response.payload.monthlySpecies) {
            const speciesData = response.payload.monthlySpecies

            // SUM TOTAL VALUE
            const totalValue = speciesData.reduce(
              (sum, item) => sum + item.total,
              0,
            )

            // SORT DATA FROM LARGEST TO SMALLEST
            const sortedData = speciesData.sort((a, b) => b.total - a.total)

            // GET TOP MAX_SEGMENTS, AND MERGE REMAINING TO "OTHERS"
            const topData = sortedData.slice(0, MAX_SEGMENTS)
            const otherData = sortedData.slice(MAX_SEGMENTS)

            const otherTotal = otherData.reduce(
              (sum, item) => sum + item.total,
              0,
            )

            // HANDLE OTHERS
            const finalData = [...topData]
            if (otherTotal > 0) {
              finalData.push({
                species: 'Others',
                total: otherTotal,
                details: otherData,
              })
            }

            setChartData({
              series: finalData.map((item) => item.total),
              labels: finalData.map((item) => item.species),
              total: totalValue,
              othersTotal: otherTotal,
              othersDetails: otherData,
              monthlySpecies: speciesData,
            })
          }
        })
        .catch((error) => {
          console.warn('Error fetching monthly data:', error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [dispatch, selectedYearAnnual?.value])

  const optionsSpecies = {
    chart: {
      type: 'donut',
    },
    labels: chartData?.labels || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      position: 'bottom',
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        colors: ['#fff'],
        fontWeight: 'normal',
        textShadow: 'none',
      },
      formatter: (val, opts) => {
        const seriesIndex = opts.seriesIndex

        // GET SPECIES BASED ON INDEX
        const speciesName = chartData?.labels[seriesIndex]
        const speciesData = chartData?.monthlySpecies?.find(
          (species) => species.species === speciesName,
        )

        const totalAmount = speciesData ? speciesData.total : 0

        // SHOWING TOTAL IN OTHERS
        if (speciesName === 'Others') {
          return chartData?.othersTotal
            ? `${newFormatThousandSeparator(chartData.othersTotal)} ${UNIT_KG}`
            : `0 ${UNIT_KG}`
        }

        return `${newFormatThousandSeparator(totalAmount)} ${UNIT_KG}`
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              color: '#000',
              fontWeight: 'normal',
              offsetY: -10,
              textShadow: 'none',
              formatter: () => 'Total',
            },
            value: {
              show: true,
              fontSize: '20px',
              color: '#000',
              fontWeight: 'normal',
              offsetY: 10,
              formatter: () => {
                return chartData?.total
                  ? `${newFormatThousandSeparator(chartData.total)} ${UNIT_KG}`
                  : `0 ${UNIT_KG}`
              },
              textShadow: 'none',
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '14px',
              fontWeight: 'normal',
              textShadow: 'none',
              formatter: () => {
                return chartData?.total
                  ? `${newFormatThousandSeparator(chartData.total)} ${UNIT_KG}`
                  : `0 ${UNIT_KG}`
              },
            },
          },
        },
      },
    },
    colors: ['#6AB04A', '#2980B9', '#E74C3C', '#F1C40F', '#9B59B6', '#6FD195'],
    tooltip: {
      custom: function ({ seriesIndex, series, dataPointIndex, w }) {
        const color = w.config.colors[seriesIndex] || '#000' // Ambil warna dari konfigurasi
        const label = chartData?.labels[seriesIndex] || 'Unknown'
        const totalValue =
          series && series[seriesIndex]
            ? newFormatThousandSeparator(series[seriesIndex])
            : '0'

        if (label === 'Others') {
          const othersDetails =
            chartData?.othersDetails && chartData.othersDetails.length > 0
              ? chartData.othersDetails
                  .map(
                    (item) =>
                      `<strong>${item.species}:</strong> ${newFormatThousandSeparator(item.total)} ${UNIT_KG}`,
                  )
                  .join('<br />')
              : 'No additional data available'

          return `
            <div style="background-color: ${color}; padding: 10px; border-radius: 5px; color: #fff; font-weight: normal;">
              <strong>Others:</strong><br />
              ${othersDetails}<br /><br />
              <strong>Total:</strong> ${chartData?.othersTotal ? `${newFormatThousandSeparator(chartData.othersTotal)} ${UNIT_KG}` : `0 ${UNIT_KG}`}
            </div>
          `
        }

        return `
          <div style="background-color: ${color}; padding: 10px; border-radius: 5px; color: #fff; font-weight: normal;">
            <strong>${label}:</strong> ${totalValue} ${UNIT_KG}
          </div>
        `
      },
    },
  }

  const columnsSpecies = [
    {
      name: <span>Spesies</span>,
      selector: (row) => row?.species ?? '-',
    },
    {
      name: <span>Total</span>,
      selector: (row) => `${newFormatThousandSeparator(row.total)} ${UNIT_KG}`,
    },
  ]

  if (loadingParent) {
    return <LoadingV2 />
  }

  return (
    <>
      <div>
        <div className="d-flex flex-column flex-sm-row align-items-start justify-content-md-between">
          <Col xs="12" sm="6">
            <h5 className="mb-0">Berdasarkan Gear</h5>
          </Col>
          <Col
            xs="12"
            sm="6"
            className="my-2 mb my-sm-0 d-flex justify-content-end"
          >
            <div style={{ width: '100%', maxWidth: '300px' }}>
              <PMBSelect
                value={selectedYearAnnual}
                onChange={handleYearChangeAnnual}
                options={yearOptionsAnnual}
                isMulti={false}
                placeholder="Pilih tahun"
              />
            </div>
          </Col>
        </div>
        <div
          style={{
            overflowX: 'auto',
            width: '100%',
          }}
        >
          <Chart options={options} height={400} series={series} />
        </div>

        {/* TABLE */}
        <div style={{ marginTop: '24px' }}>
          <DataTable
            columns={columnsAnnual}
            data={dataResultAnnual}
            progressPending={loadingParent}
            pagination={false}
            responsive
            progressComponent={
              <div style={{ margin: '24px 0px' }}>
                <Spinner color="primary" />
              </div>
            }
          />
        </div>
      </div>

      {/* SPECIES */}
      <div>
        <div className="d-flex flex-column flex-sm-row align-items-start justify-content-md-between mt-5">
          <h5 className="mb-0">Berdasarkan Spesies</h5>
        </div>

        <Row className="mt-4">
          <Col xs="12" md="6">
            {chartData?.labels?.length > 0 ? (
              <Chart
                options={optionsSpecies}
                series={chartData.series}
                type="donut"
                height={400}
              />
            ) : (
              <>Tidak ada data yang tersedia.</>
            )}
          </Col>

          <Col xs="12" md="6">
            {!loading && chartData?.labels?.length > 0 && (
              <DataTable
                columns={columnsSpecies}
                data={chartData.monthlySpecies}
                progressPending={loading}
                progressComponent={
                  <div style={{ margin: '100px 0px' }}>
                    <Spinner color="primary" />
                  </div>
                }
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AnnualTab
