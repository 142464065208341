import { TableComponent as DataTable } from 'components/TableComponent'
import { monthsOptions } from 'constants'
import moment from 'moment-timezone'
import Chart from 'react-apexcharts'
import { Spinner } from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'

const AnnualTab = ({ dataCashFlow, dataIncome, dataExpense, res }) => {
  const optionsAnnual = {
    chart: {
      type: 'bar',
      height: 500,
      zoom: { enabled: false },
      toolbar: { show: false },
      stacked: false,
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: '25px',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        shape: 'circle',
      },
    },
    colors: ['#039855', '#D92D20'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return newFormatThousandSeparator(value)
        },
      },
    },
    xaxis: {
      categories: monthsOptions.map((month) => month?.label?.slice(0, 3)),
      labels: { style: { fontSize: '12px', colors: '#6E6E6E' } },
    },
    title: {
      text: 'Jumlah (Rupiah)',
      offsetY: 5,
      style: {
        fontSize: '12px',
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return newFormatThousandSeparator(value)
        },
        style: { fontSize: '12px', colors: '#6E6E6E' },
      },
    },
  }

  const seriesAnnual = [
    { name: 'Pemasukan', data: dataIncome },
    { name: 'Pengeluaran', data: dataExpense },
  ]

  const monthsMap = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]

  const transformedData = monthsMap.map((month, index) => {
    const monthlyIncome = dataCashFlow?.items
      ?.filter(
        (item) =>
          moment(item.createddate).month() === index &&
          item.incomeorexpense === 'income',
      )
      ?.reduce((sum, item) => sum + item.amount, 0)

    const monthlyExpense = dataCashFlow?.items
      ?.filter(
        (item) =>
          moment(item.createddate).month() === index &&
          item.incomeorexpense === 'expense',
      )
      ?.reduce((sum, item) => sum + item.amount, 0)

    return {
      month,
      income: monthlyIncome,
      expense: monthlyExpense,
      profit: monthlyIncome - monthlyExpense,
    }
  })

  const columnsAnnual = [
    {
      name: <span>Periode</span>,
      selector: (row) => row?.month,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pemasukan</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.income)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pengeluaran</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.expense)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Untung/Rugi</span>,
      selector: (row) => (
        <span style={{ color: row.profit >= 0 ? '#027A48' : '#B42318' }}>
          {row.profit >= 0 ? '+ ' : '- '}
          Rp{newFormatThousandSeparator(Math.abs(row.profit))}
        </span>
      ),
      wrap: true,
      grow: 2,
    },
  ]

  return (
    <>
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
          padding: '20px 0px',
        }}
      >
        <Chart
          options={optionsAnnual}
          series={seriesAnnual}
          type="bar"
          height={400}
        />
      </div>

      <DataTable
        columns={columnsAnnual}
        data={transformedData}
        progressPending={res?.isFetching}
        progressComponent={
          <div style={{ margin: '100px 0px' }}>
            <Spinner color="primary" />
          </div>
        }
      />
    </>
  )
}

export default AnnualTab
