import { P } from 'components'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_FARMERS, URL_FARMERS_GROUPS } from 'utils/pathUrl'
const tabManageStaff = [
  {
    id: 1,
    title: 'Anggota',
    page: URL_FARMERS,
  },
  {
    id: 2,
    title: 'Grup',
    page: URL_FARMERS_GROUPS,
  },
]

export default function TabManageStaffGroup() {
  const navigate = useNavigate()
  let location = useLocation()

  return (
    <div
      className="mb-3"
      style={{
        borderBottom: '1px solid #EAECF0',
      }}
    >
      <div className="d-flex align-items-center gap-3">
        {tabManageStaff?.map((el, i) => {
          return (
            <div
              onClick={() => {
                navigate(el?.page)
              }}
              className="pb-2"
              style={{
                cursor: 'pointer',
                borderBottom:
                  el?.page === location?.pathname ? '2px solid #005370' : null,
              }}
            >
              <P
                size="regular"
                bold
                color={el?.page === location?.pathname ? '#005370' : '#667085'}
              >
                {el?.title}
              </P>
            </div>
          )
        })}
      </div>
    </div>
  )
}
