import { P } from 'components'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import { Can } from 'context/Acl'
import { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  CheckCircle,
  ChevronDown,
  Edit2,
  Plus,
  Search,
  Trash2,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import { BUSINESS_COMPANY, MANAGE } from 'utils/subjectActions'
import TabManageStaffGroup from '../components/TabManageStaffGroup'
import {
  toggleModalDelete,
  toggleModal as toggleModalEditGroup,
} from '../farmers-list/farmersListSlice'
import ModalChangeName from '../farmers-list/ModalChangeName'
import ModalConfirmDelete from '../farmers-list/ModalConfirmDelete'
import { clearState } from '../forms/formSlice'
import { getGroupCount, getGroupList } from './groupListAction'
import { toggleBanner, toggleModal } from './groupsListSlice'
import ModalCreateGroup from './ModalCreateGroup'
import debounce from 'lodash.debounce'

const GroupsList = () => {
  const { tag, isOpenBanner, loading, bannerMsg, groups, allGroupCount } =
    useSelector((s) => s.groupsList)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [selectedGroup, setSelectedGroup] = useState({})
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  useEffect(() => {
    dispatch(clearState())
  }, [])

  useEffect(() => {
    dispatch(getGroupList({ page, size }))
  }, [size, page])

  useEffect(() => {
    if (!allGroupCount) {
      dispatch(getGroupCount())
    }
  }, [allGroupCount])

  const debouncedSearch = useCallback(
    debounce((value) => {
      dispatch(getGroupList(value))
    }, 300),
    [dispatch],
  )

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearch(value)
    debouncedSearch(value)
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  const columns = [
    {
      name: <span>Nama Grup</span>,
      // width: '40%',
      grow: 2,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <>
              <span>{row.name}</span>
            </>
          </div>
        )
      },
    },
    {
      name: <span>Jumlah Anggota</span>,
      grow: 2,
      cell: (row) => {
        return <span>{row?.totalMember}</span>
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <Can I={MANAGE} this={BUSINESS_COMPANY}>
            <Edit2
              size={16}
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedGroup(row)
                dispatch(toggleModalEditGroup())
              }}
            />
            <Trash2
              size={16}
              className="me-2"
              style={{ cursor: 'pointer', color: '#B42318' }}
              onClick={() => {
                setSelectedGroup(row)
                dispatch(toggleModalDelete())
              }}
            />
          </Can>
        )
      },
    },
  ]

  return (
    <div className="w-100 px-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs
          breadCrumbActive={'Grup'}
          breadCrumbParent={'Daftar Anggota'}
          breadCrumbParentLink={'/farmers'}
        />
      </div>
      <ModalChangeName groupCode={selectedGroup?.code} />
      <ModalConfirmDelete groupCode={selectedGroup?.code} />
      <ModalCreateGroup />
      <Card className="border-0 shadow-sm">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={groups?.items}
            // selectableRows
            // onSelectedRowsChange={(e) => console.log(e)}
            // progressPending={isLoading}
            responsive
            persistTableHead
            progressComponent={<Spinner />}
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada grup yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={groups?.totalItems}
                currentPage={groups?.currentPage}
                rowsPerPage={size}
                setCurrentPage={(e) => {
                  setPage(e)
                }}
                setRowsPerPage={(e) => {
                  setSize(e)
                }}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <TabManageStaffGroup />
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="fw-semibold">Daftar Grup</h5>
                  <div>
                    <CustomButton
                      className="d-flex align-items-center gap-1"
                      color="primary"
                      onClick={() => dispatch(toggleModal())}
                    >
                      <Plus size={14} />
                      <P size="regular">Tambah Grup</P>
                    </CustomButton>
                  </div>
                </div>
                <div className="d-flex gap-2 mb-4">
                  <InputGroup style={{ width: '240px' }}>
                    <Input
                      placeholder="Cari grup..."
                      onChange={handleSearchChange}
                      value={search}
                    />
                    <InputGroupText
                      style={{
                        backgroundColor: '#ffffff',
                        borderLeft: '1px solid #ced4da',
                      }}
                    >
                      <Search size={15} style={{ cursor: 'pointer' }} />
                    </InputGroupText>
                  </InputGroup>
                </div>
                <div>
                  <Alert
                    isOpen={isOpenBanner}
                    toggle={() => dispatch(toggleBanner())}
                    color="success"
                    className="d-flex align-items-center mt-4 w-100"
                  >
                    <span>
                      <CheckCircle size={17} /> Grup berhasil ditambah.
                    </span>
                  </Alert>
                </div>
              </div>
            }
          />
          {/* <div className="w-100">
            <div className="d-flex align-items-center mb-1">
              {loading ? (
                <Spinner color="primary" />
              ) : (
                <>
                  {groups?.totalItems > 0 ? (
                    <div className="d-flex justify-content-between align-items-center w-100 pb-3">
                      <h5 className="fw-semibold">Daftar Grup</h5>
                      <Can I={MANAGE} this={BUSINESS_FARMERS}>
                        <Button
                          onClick={() => dispatch(toggleModal())}
                          color="primary"
                        >
                          + Buat Grup
                        </Button>
                      </Can>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <div>
              <Alert
                isOpen={isOpenBanner}
                toggle={() => dispatch(toggleBanner())}
                color="success"
                className="d-flex align-items-center mt-4 w-100"
              >
                <span>
                  <CheckCircle size={17} /> {bannerMsg}
                </span>
              </Alert>
            </div>
            <Row className="d-flex flex-row justify-content-start mb-3 align-items-stretch pb-3 gap-3">
              <Col lg="2" md="6" sm="12" className="d-flex">
                <Card body className="w-100 border-0 shadow-sm">
                  <CardText tag="small" className="fw-semibold">
                    Jumlah Grup
                  </CardText>
                  <CardText tag="h3" className="text-primary mt-2">
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      allGroupCount?.totalGroup
                    )}
                  </CardText>
                </Card>
              </Col>
              <Col lg="2" md="6" sm="12" className="d-flex">
                <Card body className="w-100 border-0 shadow-sm">
                  <CardText tag="small" className="fw-semibold">
                    Jumlah Anggota
                  </CardText>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="text-primary h3">
                      {loading ? (
                        <Spinner color="primary" />
                      ) : (
                        allGroupCount?.totalFarmer
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          {loading ? (
            <div>
              <Spinner color="primary" />
            </div>
          ) : groups?.totalItems > 0 ? (
            <div className="d-flex flex-column gap-3">
              {groups?.items?.map((e, i) => {
                return (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/farmers/groups/${e?.code}`)
                    }}
                    key={i}
                  >
                    <div className="d-flex flex-column gap-1">
                      <span className="fw-500">{e?.name}</span>
                      <span className="text-muted">
                        Jumlah anggota: {e?.totalMember}
                      </span>
                    </div>
                    <ChevronRight size={20} className="text-secondary" />
                  </div>
                )
              })}
            </div>
          ) : (
            <Card className="bg-light border-0 shadow-sm">
              <CardBody>
                <div className="d-flex flex-column align-items-center gap-4">
                  <img alt="Groups" src={emptyAnggota} />
                  <span>
                    Gunakan grup untuk mengatur pekerja lapangan berdasarkan
                    wilayah operasi.
                  </span>
                  <Can I={MANAGE} this={BUSINESS_FARMERS}>
                    <Button
                      onClick={() => dispatch(toggleModal())}
                      color="primary"
                    >
                      + Buat Grup
                    </Button>
                  </Can>
                </div>
              </CardBody>
            </Card>
          )} */}
        </CardBody>
      </Card>
    </div>
  )
}

export default GroupsList
